$primary: #496463;
$btn-border-radius-sm: 20px;

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

@import "../node_modules/bootstrap/scss/bootstrap";

html,
body {
    height: 100%;
    width: 100%;
    font-family: "Noto Sans JP", sans-serif;
}

#root {
    height: 100%;
    width: 100%;
}

#main-container {
    height: 100%;
    width: 100%;
    padding-top: 64px;
}

// #sidebar {
//     width: 208px;
//     max-width: 208px;
//     min-width: 208px;

//     background-color: orange;

//     #logo {
//         height: 63px;
//         background: #bff155;
//         border-radius: 20px 20px 0px 0px;
//     }

//     #nav {
//         background: linear-gradient(180deg, #496463 0%, #1b1e20 100%);
//         border-radius: 0px 0px 20px 20px;
//         --bs-navbar-color: rgba(255, 255, 255, 0.75);
//         --bs-navbar-hover-color: rgba(255, 255, 255, 1);
//     }
// }

#toolbar {
    height: 63px;
    background: linear-gradient(180deg, #f9f9f9 0%, #efefef 100%);
    background-clip: content-box;
}

#screenshot-thumbnail-icon {
    width: 32px;
    height: 32px;
}

#contents {
    overflow-y: scroll;
}

#editor-container {
    #editor-pane {
        #codemirror {
            height: 100% !important;
        }
    }

    #preview-pane {
        #preview-container {
            top: 100px;
            right: 0;
        }
    }
    #console-pane {
        height: 200px;
    }

    #fileuploader {
        position: relative;
        #uploading {
            position: absolute;
            left: 0px;
            top: 0px;
            background: rgba(255, 255, 255, 0.8);
        }
    }
}

#sketches-container {
    height: 100%;
    .card-img-overlay {
        opacity: 0;
        transition: opacity 0.5s;
    }
    .card-img-overlay:hover {
        opacity: 1;
    }

    .title {
        font-size: 1em;
    }
    .uname {
        font-size: 0.8em;
    }
}

#profile-container {
    #profile-image-size {
        width: 300px;
    }
}

.box {
    border-radius: 20px;
    background: linear-gradient(180deg, #f9f9f9 0%, #efefef 100%);
    box-shadow: 20px 20px 50px 0px #00000040;
}

.shadow {
    box-shadow: 20px 20px 50px 0px #00000040;
}

#fullscreen-container {
    background-color: black;

    #sidemenu {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 360px;
        height: 100%;
        background: linear-gradient(180deg, #1b1e20 0%, #0d0d0d 100%);
        color: white;

        #sidemenu-button {
            position: relative;
            left: 100%;
            top: 100px;
            width: 24px;
            height: 120px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
            }
        }
        #sidemenu-content {
            position: absolute;
            top: 0px;
        }
    }

    #exit-fullscreen {
        position: absolute;
        top: 30px;
        right: 30px;
    }
}

@import "@yaireo/tagify/dist/tagify.css";
:root {
    --tagify-dd-color-primary: #dddddd; // should be same as "$tags-focus-border-color"
    --tagify-dd-bg-color: white;
    --tagify-dd-item-pad: 0.3em; // should be same as $tag-pad (below)
}

.tagify {
    --tag-pad: 0.3em;
    --tag-inset-shadow-size: 5em;
    --tag-text-color: white;
    --tag-text-color--edit: var(--tag-bg);
    --tag-remove-btn-color: white;
    --tag-remove-bg: #dddddd;
    --tag-border-radius: 0.375rem;
    --tag-remove-btn-bg--hover: #666;
    --tagify-dd-bg-color: red;
    font-weight: bold;
    font-size: 0.8em;
}

.tagify.form-control {
    padding: 0px 5px 0px 5px;
}

.tagify__tag {
    height: 20px;
    line-height: 1em;
    padding: 0px 3px 0px 6px;
}

.tags-look .tagify__dropdown__item {
    display: inline-block;
    vertical-align: middle;
    border-radius: 0.375rem;
    padding: 0px 6px 0px 6px;
    border: 1px solid;
    margin: 0.2em;
    font-weight: bold;
    font-size: 0.8em;
    transition: 0s;
}

#profile-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: center;
        transform: scale(1);
    }
}

#sidebar-button {
    $body-color: #111;
}

//
.profile-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;

    img {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        object-fit: cover;
        object-position: center;
    }
}

// ランディングページ
#landing {
    background: #ffffff;

    header {
        background: #ffffff;
    }
    #copy {
        padding-top: 5em;
        padding-bottom: 5em;
        #copy1 {
            font-size: 3em;
        }
        #copy2 {
            font-size: 2em;
        }
    }
    #samples {
        background: #ffffff;
    }
    footer {
        background: #eeeeee;
    }
}

// コンポーネント
nav {
    position: fixed !important;
    height: 64px;
    width: 100%;
    background: #bff155;
    box-shadow: 0px 2px 5px 0px #00000040;
}
#navspacer {
    height: 64px;
}

// フルスクリーン
.slide-start {
    transform: translateX(-100%); /* 左側からスライドインする位置 */
}

.slide-in {
    transform: translateX(-100%); /* 左側からスライドインする位置 */
    animation: slideIn 0.5s forwards; /* スライドインのアニメーションを1秒間実行 */
}

@keyframes slideIn {
    to {
        transform: translateX(0%); /* 右側までスライドイン */
    }
}

.slide-out {
    transform: translateX(0%); /* 左側からスライドインする位置 */
    animation: slideOut 0.5s forwards; /* スライドインのアニメーションを1秒間実行 */
}

@keyframes slideOut {
    to {
        transform: translateX(-100%); /* 右側までスライドイン */
    }
}

.sketch-profile {
    cursor: pointer;
}

.sketch-thumbnail {
    cursor: pointer;
}

.sketch-title {
    cursor: pointer;
}

.sketch-uname {
    font-size: 0.8em;
    color: #aaa;
    cursor: pointer;
}

.sketch-tag {
    cursor: pointer;
    color: white;
    margin: 0.2em 0em;
    padding: 3px 9px 3px 9px;
    line-height: 1em;
    border-radius: 0.375rem;
    font-weight: bold;
    font-size: 0.8em;
}

// オーバーレイ
.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    justify-content: center;
    align-items: center;
}

.sketch-item .dropdown-toggle::after {
    display: none;
}

.fileuploader {
    position: relative;
    .uploading {
        position: absolute;
        left: 0px;
        top: 0px;
        background: rgba(255, 255, 255, 0.8);
    }
}

.warning {
    color: red;
}

// Split

.split {
    display: flex;
    flex-direction: row;
}

.gutter {
    background-color: #eee;
    background-repeat: no-repeat;
    background-position: 50%;
}

.gutter.gutter-horizontal {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==");
    cursor: col-resize;
}
.gutter.gutter-vertical {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAFAQMAAABo7865AAAABlBMVEVHcEzMzMzyAv2sAAAAAXRSTlMAQObYZgAAABBJREFUeF5jOAMEEAIEEFwAn3kMwcB6I2AAAAAASUVORK5CYII=");
    cursor: row-resize;
}
